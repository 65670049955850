import React from 'react';
import Img from 'gatsby-image';
import { graphql, StaticQuery } from 'gatsby';
import '../assets/stylesheets/pages/404.scss';
import VisuallyHidden from '@reach/visually-hidden';

export default function FourOhFourPage() {
  return (
    <div className="wrapper">
      <StaticQuery
        query={graphql`
          query {
            file(
              sourceInstanceName: { eq: "global-images" }
              relativePath: { eq: "404.png" }
            ) {
              childImageSharp {
                fluid(maxWidth: 600, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        `}
        render={(data) => (
          <>
            <Img
              alt="An old wooden sign peppered with bullet holes and faded paint spelling out the words Population 404"
              fluid={data.file.childImageSharp.fluid}
            />
            <VisuallyHidden>
              The page you are looking for was not found.
            </VisuallyHidden>
          </>
        )}
      />
    </div>
  );
}
